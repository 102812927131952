import React from 'react'
import { useState, useEffect } from 'react';

import axios from 'axios';
import { Link } from 'react-router-dom';
import Alert from '../components/Alert';


const Home = () => {

  const [alertData, setAlertData] = useState({ msg: '', type: '' });
  const [showAlert, setShowAlert] = useState(false);

  const [post, setPost] = useState([]);
  useEffect(() => {
    axios.get('https://wambaafrica.com/blog/wp-json/wp/v2/Posts?_embed').then((res) => {
      setPost(res.data)
      // console.log(res.data)
    })
    // console.log(post)
  }, [])




  // ........................ .......
  const [formdata, setFormdata] = useState({
    cf_name: "",
    cf_email: "",
    cf_phone: "",
    cf_interest: "",
    cf_message: "",
  })
  // ................................


  const OnchangeData = (e) => {
    setFormdata({
      ...formdata,
      [e.target.name]: e.target.value
    })
    // console.log(formdata)
  }
  // ...................................

  const formatDate = (gmtDate) => {
    let date = new Date(gmtDate)
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
      "July", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    return date.getDate() + ' ' + monthNames[date.getMonth()] + ' ' + date.getFullYear()
  }

  const Onhandleform = (e) => {
    e.preventDefault();
    // console.log(formdata);
    setAlertData({
      msg: `Thank you for sending your Booking Request,`,
      type: " We'll get back to you as soon as possible.",
    });
    setShowAlert(true);
    setFormdata({
      cf_name: "",
      cf_email: "",
      cf_phone: "",
      cf_interest: "",
      cf_message: "",
    })
  }

  // axios({
  //   method: "post",
  //   url: "http://localhost:3001/userdata",
  //   data: formdata
  // }).then((res) => {
  // })


  return (

    <>

      <div
        className="mad-media-element"
        data-bg-image-src="/images/wamba-pop3.jpg"
      >
        <div className="mad-media-inner">
          <div className="container">
            <div className="mad-pre-title">Maryanne Wamba</div>
            <h3 className="mad-title" style={{ fontSize: "2.5rem" }}>
              {" "}
              Counseling Psychologist, <br></br>Trauma Informed Social Worker,<br></br> Rapid Transformation Practitioner! {" "}
            </h3>
            <p className="content-element-6">
              <b>
                "As a teletherapist, I aspire to combine my passion for mental health wellness {" "}
                <br /> with personal and professional experience to support African migrants in {" "}
                <br />Europe cope and thrive."
              </b>
            </p>
            <a href="#contact" className="btn scroll-to-section btn-huge">
              Book A Session
            </a>
          </div>
        </div>
      </div>
      <div className="mad-content no-pd">
        <div className="container-fluid">

          <div className="mad-section align-center mad-section--stretched mad-colorizer--scheme-color with-texture12">

            <div className="mad-pre-title">Mental Health Wellness</div>
            <h4 className="mad-title">
              {" "}
              Health is a state of complete physical, mental  and social well-being and not merely the{" "}
              absence of disease or infirmity.
            </h4>
            <p className="content-element-6 mad-text-medium">
              {" "}
              WHO – Mental health risks are always imminent and the impacts have varying degrees.
              <br />
            </p>


          </div>

          <div className="mad-section mad-changing-bg with-overlay no-pd mad-section--stretched-content mad-colorizer--scheme-">
            <div className="mad-colorizer-bg-color">
              <div
                className="mad-entity-bg with-overlay"
                data-bg-image-src="/images/entity_bg1.jpg" alt="photo"
              />
            </div>
            <div className="mad-entities-wrap">
              <div className="mad-entities type-5 item-col-3 no-gutters">
                <div className="mad-col">
                  {/*================ Entity ================*/}
                  <article className="mad-entity mad-change-bg" data-bg-img={1}>
                    <div className="mad-entity-content">
                      <div className="mad-entity-header">
                        <h5 className="mad-entity-title">
                          Individual Therapy
                          {/* <a href="portfolio_single_img.html">Individual Therapy</a> */}
                        </h5>

                      </div>
                    </div>
                  </article>
                  {/*================ End of Entity ================*/}
                </div>
                <div className="mad-col">
                  {/*================ Entity ================*/}
                  <article className="mad-entity mad-change-bg" data-bg-img={1}>
                    <div className="mad-entity-content">
                      <div className="mad-entity-header">
                        <h5 className="mad-entity-title">
                          Hypnotherapy
                          {/* <a href="portfolio_single_img.html">Couple Therapy</a> */}
                        </h5>

                      </div>
                    </div>
                  </article>
                  {/*================ End of Entity ================*/}
                </div>
                <div className="mad-col">
                  {/*================ Entity ================*/}
                  <article className="mad-entity mad-change-bg" data-bg-img={1}>
                    <div className="mad-entity-content">
                      <div className="mad-entity-header">
                        <h5 className="mad-entity-title" style={{ marginTop: "-39px" }}>
                          Psychoeducation (Group Therapy, Trainings & Seminars).
                          {/* <a href="portfolio_single_img.html">Family Therapy</a> */}
                        </h5>

                      </div>
                    </div>
                  </article>
                  {/*================ End of Entity ================*/}
                </div>

              </div>
            </div>
          </div>

          <div className="mad-section align-center mad-section--stretched mad-colorizer--scheme-color with-texture12">




            <div className="mad-pre-title">Mission</div>

            <p className="content-element-6 mad-text-medium">
              {" "}
              Educate and Empower African Women on Mental Health.
              <br />
            </p>
            <img src="/images/wambaimg.jpeg" className="img-fluid" alt="" srcset="" />
          </div>


          <div className="row no-row-gap mad-column-reverse align-items-center my-5">
            <div className="col-xl-6">
              <div className="mad-img-content">
                <div className="row justify-content-center">
                  <div className="col-xxl-8">
                    <div className="mad-pre-title">shortly about me</div>
                    <h2 className="mad-title">Why Maryanne</h2>
                    <div className="mad-text-medium content-element-6">
                      <p style={{ marginTop: "-34px" }}>
                        {" "}
                        I am a Kenyan based in Germany. Trained in Rapid Transformational Therapy (RTT) in the UK by Marisa Peer and also hold Masters Degree in Counseling Psychology; Masters Degree in Social Work with Family and Children and a certificate in Trauma Healing. I offer empowering insights and tools to women as they navigate and overcome childhood traumas, destructive behavior, procrastination, self-doubt among others enabling them become their most confident selves without spending years in therapy.
                        {" "}
                      </p>
                      <p style={{ marginTop: "-13px" }}>
                        {" "}
                        <h6><b>
                          Experience
                        </b>
                        </h6>
                        <p style={{ marginTop: "-14px" }}>I have worked with groups and individuals in different capacities and environments such as rehabilitation centers, prisons, remand centers and virtual settings on diverse topics including training with trauma and childhood traumas, addictions, anxiety, depression, self-sabotage, negative thinking patterns, unhealthy relationship patterns among others…</p>
                        <h6><b>My Passion</b> </h6>
                        <p style={{ marginTop: "-14px" }}>To be a thought leader, playing an active role in mental wellness, Psychoeducation and  become the voice of African migrants across Europe</p>



                        <p>
                          <div class="collapse" id="collapseExample">
                            <div>
                              <h6><b>My Why.</b></h6>
                              <p style={{ marginTop: "-14px" }}>I see mental health as an encroaching pandemic in Africa, affecting all but burdening the woman most. Personally, it is the one thing that continued to hold me hostage, for most of my life. Knowing you can do more than you are doing but lacking the will and courage to make the big steps. </p>
                              <p style={{ marginTop: "-26px" }}>
                                I am also not happy with how collectively dysfunctional our society is becoming. I am not happy with the position the black woman is occupying there has to be more for her. I can't change the world but this!

                                This is my contribution to society and to the bigger picture.

                                QUOTE. “Be the hummingbird’ Do the little you can ” – Wangari Maathai.</p>
                              <h6><b>Qualifications</b></h6>

                              <ul style={{ listStyleType: "disc", lineHeight: "1.1", marginLeft: "8px" }}><li><b>2022</b> - Rapid Transformational Therapist, The School of Rapid Transformational Therapy, UK</li><br></br>
                                <li><b>2020</b> - Master in Social Work with Family and Children, (Portugal, Norway, Sweden)</li><br></br>
                                <li><b>2013 </b>- Master Counseling Psychology, United State International University, Kenya</li><br></br>
                              </ul>
                            </div>


                          </div>
                          <a class="btn btn-primary" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                            Read Wamba's Bio
                          </a>


                        </p>
                      </p>
                    </div>


                    {" "}

                  </div>

                </div>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="mad-img">
                <img src="/images/wamba_profile-preview-2.png" alt="" />
              </div>
            </div>
          </div>
        </div>




        <div className="mad-section mad-section--stretched mad-colorizer--scheme-color-2 with-texture2">
          <div className="container">
            {/*================ Testimonials ================*/}
            <div className="mad-testimonials with-quotes align-center">
              <div className="mad-grid mad-grid--cols-1 owl-carousel">
                {/* owl item */}
                <div className="mad-grid-item">
                  <div className="mad-testimonial">
                    <div className="mad-testimonial-info">
                      <blockquote>
                        <p>
                          {" "}
                          "Thoughts make a good servant but a poor master"{" "}
                        </p>
                      </blockquote>
                    </div>
                    <div className="mad-author">
                      <div className="mad-author-info">
                        <span className="mad-author-name">
                          Jack Kornfield
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* / owl item */}
                {/* owl item */}
                <div className="mad-grid-item">
                  <div className="mad-testimonial">
                    <div className="mad-testimonial-info">
                      <blockquote>
                        <p>
                          {" "}
                          "Change your thoughts and you change your world."{" "}
                        </p>
                      </blockquote>
                    </div>
                    <div className="mad-author">
                      <div className="mad-author-info">
                        <span className="mad-author-name">
                          Norman Vincent Peale
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* / owl item */}
                {/* owl item */}
                <div className="mad-grid-item">
                  <div className="mad-testimonial">
                    <div className="mad-testimonial-info">
                      <blockquote>
                        <p>
                          {" "}
                          “All behaviors are preceded by thoughts, an untrained mind will give you excuses”{" "}
                        </p>
                      </blockquote>
                    </div>
                    <div className="mad-author">
                      <div className="mad-author-info">
                        <span className="mad-author-name">
                          Wayne W. Dyer
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* / owl item */}
              </div>
            </div>
            {/*================ End of Testimonials ================*/}
          </div>
        </div>





        <div
          className="mad-section with-overlay mad-section--stretched-content mad-colorizer--scheme-light mad-colorizer--parallax py-5"
          data-bg-image-src="images/1920x1000_bg1.jpg"
        >
          <div className="container">
            <div className="mad-title-wrap align-center">
              <div className="mad-pre-title">A successful Wellness Journey</div>
              {/* <h2 className="mad-title">Pricing Packages</h2> */}
            </div>
            <div className="mad-pt item-col-3">
              <img src="images/women.jpeg" alt="" style={{ maxHeight: "700px" }} width="100%" />
              <img src="images/african-2.jpeg" alt="" style={{ maxHeight: "700px" }} width="100%" />
              <img src="images/african-4.jpg" alt="" style={{ maxHeight: "700px" }} width="100%" />
            </div>
          </div>
        </div>




        <div className='my-5'>
          <div className="mad-title-wrap align-center">
            <div className="mad-pre-title">Blog Feed</div>
            <h2 className="mad-title">Latest Articles</h2>
            <div class="mad-entities type-2 item-col-3">
              {post.map((result) => (
                <div className="mad-col" >








                  <article className="mad-entity">
                    <div className="mad-entity-media">
                      <a href="https://wambaafrica.com/blog/">
                        <img src={result._embedded['wp:featuredmedia']['0'].media_details.sizes.full.source_url} alt="" style={{ height: "472px" }} />
                      </a>
                    </div>
                    <div className="mad-entity-content">
                      <div className="mad-entity-header">
                        <div className="mad-entity-tags">

                          <span>

                            <a href="https://wambaafrica.com/blog/" className="mad-link">


                            </a>
                            <span style={{ color: "black" }}>Posted On</span>
                          </span>

                          <span>

                            <a href="https://wambaafrica.com/blog/" className="mad-link">

                              {
                                formatDate(result.date_gmt)
                              }
                            </a>
                          </span>
                        </div>
                        <h5 className="mad-entity-title">
                          <a href="https://wambaafrica.com/blog/">
                            {result.title.rendered}
                          </a>
                        </h5>

                        <a href={result.link} className="mad-text-link">
                          {" "}
                          Read More{" "}
                        </a>
                      </div>
                    </div>

                  </article>




                  {/* ================ End of Entity ================ */}
                </div>
              ))}
            </div>







          </div>
        </div>
      </div >
      <div >

        {/* Google map section */}
        < div className="mad-gmap-wrap pt-5 pb-4" >
          <div class="container-fluid">
            <div class="row" id="contact">
              <div className="col-lg-6">
                <div class="mad-gmap-element" style={{ paddingBottom: "20px" }}>
                  <div class="mad-title-wrap">
                    <div class="mad-pre-title">get in touch</div>
                    <h2 class="mad-title">Contact Details</h2>
                  </div>

                  <ul class="mad-vr-list mad-map-info content-element-3">

                    <li>
                      <img src="psychologist_icons_svg/clock4.svg" alt="" class="svg" /> Monday - Saturday

                    </li>
                    <li>
                      <img src="psychologist_icons_svg/network.svg" alt="" class="svg" />
                      <div class="mad-social-icons">
                        <ul>
                          {/* <li>
                          <Link to="https://facebook.com/" target="blank"><i class="fab fa-facebook-f"></i></Link>
                        </li> */}
                          <li>
                            <Link to="https://www.instagram.com/am/#waambaafrica" target="blank"><i class="fab fa-instagram"></i></Link>
                          </li>
                          <li>
                            <Link to="https://twitter.com/WambaAfrica" target="blank"><i class="fab fa-twitter"></i></Link>
                          </li>
                          {/* <li>
                  <a href="https://youtube.com/" target="blank"><i class="fab fa-youtube"></i></a>
                </li> */}
                          <li>
                            <Link to="https://www.linkedin.com/in/maryanne-wamba-3533325b/" target="blank"><i class="fab fa-linkedin-in"></i></Link>
                          </li>
                        </ul>

                      </div>
                    </li>
                  </ul>
                  <div className="row">
                    <div className="col-6"><img src="images/footer1new.png" alt="" /></div>
                    <div className="col-6"> <img src="images/footer2.png" alt="" style={{ marginTop: "20px" }} /></div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <h2 class="mad-title"> Book 30 Mins Free Discovery Call</h2>

                <Alert alertData={alertData} setShowAlert={setShowAlert} showAlert={showAlert} />



                <form class="mad-contact-form mad-form type-2 item-col-2" onSubmit={Onhandleform} method="post">
                  <div class="mad-col">
                    <div class="mad-form-item">
                      <label>Name *</label>
                      <input type="text" id="cf_name" name="cf_name" placeholder="Name" value={formdata.cf_name}
                        onChange={OnchangeData} required />
                    </div>

                    <div class="mad-form-item">
                      <label>Phone number</label>
                      <input type="tel" id="cf_phone" name="cf_phone" placeholder="Phone Number" value={formdata.cf_phone}
                        onChange={OnchangeData} required />
                    </div>



                  </div>
                  <div class="mad-col">
                    <div class="mad-form-item">
                      <label>Email *</label>
                      <input type="email" id="cf_email" name="cf_email" placeholder="Email Address" value={formdata.cf_email}
                        onChange={OnchangeData} required />
                    </div>
                    <div class="mad-form-item">
                      <label>Service's Category</label>
                      <div class="customSelect">
                        <select className='form-select py-3 customFormSelect' data-default-text="Service of Interest" id="cf_subject" name="cf_interest" value={formdata.cf_interest}

                          onChange={OnchangeData} required>
                          <option value="" hidden>Service's Category</option>

                          <option value="Individual Therapy">Individual Therapy</option>
                          <option value=" Hypnotherapy">  Hypnotherapy</option>
                          <option value="Psychoeducation">Psychoeducation </option>
                        </select>
                      </div>
                    </div>

                  </div>
                  <div className='mad-form-item item-custom'>
                    <label for="choose a date">Book a Session</label>
                    <input type="date" id="date" name="Date" />

                  </div>
                  <div class="mad-form-item full-height item-custom" style={{ marginTop: "-46px" }}>
                    <label>Message *</label>
                    <textarea rows="1" id="message" name="cf_message" placeholder="Message" value={formdata.cf_message}
                      onChange={OnchangeData} required></textarea>
                  </div>

                  <div class="mad-form-item" style={{ marginTop: "-20px" }}>
                    <button type="submit" class="btn btn-big">
                      <span>Book A Session</span>
                    </button>
                  </div>
                </form>

              </div>

              {/* <div className="col-lg-6">
             <iframe width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=india%20mohali+(india)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a href='https://maps-generator.com/'></a>
              </div> */}
            </div>
          </div>

        </div >
      </div>






      {/* google map */}



    </>



  )
}




export default Home;
